import firebase from 'firebase';
import 'firebase/firebase-auth';
import 'firebase/firebase-firestore';
import { firebaseConfig } from '../../../src/config';
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (window.location.hostname === 'localhost') {
    console.log('\x1b[35m%s', 'RUNNING WITH EMMULATOR Setup');
    const db = firebase.firestore();
    db.settings({
      host: 'localhost:8080',
      ssl: false
    });
    const auth = firebase.auth();
    auth.useEmulator('http://localhost:9099');
    const functions = firebase.functions();
    functions.useEmulator('localhost:5001');
  }
}
export default firebase;
