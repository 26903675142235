import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import { useSnackbar } from 'notistack';
// import { Icon } from '@iconify/react';
// import closeFill from '@iconify/icons-eva/close-fill';

// Application
// import { MIconButton } from '../../components/@material-extend';
import { firebaseConfig } from '../../config';
import { getAllowedUserDetailsByEmail, markAllowedUserAsRegisterd } from '../../services/userService';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['operations@poornamecovision.org'];

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (window.location.hostname === 'localhost') {
    console.log('\x1b[35m%s', 'RUNNING WITH EMMULATOR Setup');
    const db = firebase.firestore();
    db.settings({
      host: 'localhost:8080',
      ssl: false
    });
    const auth = firebase.auth();
    auth.useEmulator('http://localhost:9099');
    const functions = firebase.functions();
    functions.useEmulator('localhost:5001');
  }
}

const initialState = {
  isAuthenticated: false,
  isAdminUser: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, isAdminUser, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isAdminUser,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  addVolunteer: () => Promise
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        let admin = false;
        if (user) {
          const docRef = firebase.firestore().collection('users').doc(user.uid);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                setProfile(doc.data());
              }
            })
            .catch((error) => {
              console.error(error);
            });
          // Check if admin
          const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
          if (idTokenResult.claims.admin) {
            admin = true;
            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, isAdminUser: admin, user }
            });
          }
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, isAdminUser: admin, user }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, isAdminUser: admin, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = async (email, password, firstName, lastName, mobilenumber) => {
    return new Promise(async (resolve, reject) => {
      // await getAllowedUserDetailsByEmail(email)
      //   .then((querySnapshot) => {
      //     if(querySnapshot.size > 0) {
      //       querySnapshot.forEach((doc) => {
      //         const currentemail = doc.data().email;
      //         if (currentemail === email) {
                firebase
                  .auth()
                  .createUserWithEmailAndPassword(email, password)
                  .then((res) => {
                    firebase
                      .firestore()
                      .collection('users')
                      .doc(res.user.uid)
                      .set({
                        uid: res.user.uid,
                        email,
                        displayName: `${firstName} ${lastName}`,
                        location: 'Pune',
                        isActive: true,
                        mobilenumber,
                        createdAt: new Date()
                      })
                      .then(() => {
                        resolve('Register Successfully');
                      });
  
                    markAllowedUserAsRegisterd(email);
                  })
                  .catch((error) => {
                    console.log(error);
                    reject(error.message);
                  });
          //     } else {
          //       alert('Not Allowed');
          //     }
          //   });
          // }
        // })
        // .catch((error) => {
        //   console.log(error);
        //   throw error;
        // });
      // firebase
      //   .firestore()
      //   .collection('allowed_users')
      //   .get()
      //   .then((querySnapshot) => {
      //     let userFoundFlag = false;
      //     querySnapshot.forEach((doc) => {
      //       const currentemail = doc.data().email;
      //       if (currentemail === email) {
      //       }
      //     });
      //     if (!userFoundFlag) {
      //       reject('You are not authorized person');
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    });
  };

  const addVolunteer = (email, password, firstName, lastName, phoneNumber) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          firebase
            .firestore()
            .collection('users')
            .doc(res.user.uid)
            .set({
              uid: res.user.uid,
              email,
              displayName: `${firstName} ${lastName}`,
              location: 'Pune',
              isActive: true,
              phoneNumber
            })
            .then((resolve, reject) => {
              //.then((resolve) => {
              //console.log(`${res}`);
              resolve(`${firstName} added`);
              //});
            });
        });
    });
  };
  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          //photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(auth.email) ? 'admin' : 'user',
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
          //country: profile?.country || '',
          address: profile?.address || '',
          //state: profile?.state || '',
          city: profile?.city || '',
          //zipCode: profile?.zipCode || '',
          //about: profile?.about || '',
          isPublic: profile?.isPublic || false
        },
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        addVolunteer
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
