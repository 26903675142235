import firebase from '../contexts/firebase/FirebaseApp';
import { md_locations, md_wasteTypes } from './metadata';

const USER_COLLECTION = 'users';
const INVITED_USER_COLLECTION = 'allowed_users';
const DONOR_COLLECTION = 'donors';

export const getAllUsers = async () => {
  const db = firebase.firestore();
  return db.collection(USER_COLLECTION).get();
};

export const getAllRegisteredUsers = async () => {
  const db = firebase.firestore();
  return db.collection(USER_COLLECTION).get();
};

/**
 * Input  - list of email entered while inviting
 * 
 * @returns snapshot of allowed users
 */
export const getAllowedUsersForGivenEmails = async (emails) => {
  const db = firebase.firestore();
  return db.collection(INVITED_USER_COLLECTION).where('email', 'in', emails).get();
};

/**
 * 
 * @param {users} users to add
 * @returns promise
 */
export const inviteUsers = async (users) => {
  
  const db = firebase.firestore();
  const batch = db.batch();
  users.forEach((doc) => {
    var docRef = db.collection(INVITED_USER_COLLECTION).doc(); //automatically generate unique id
    batch.set(docRef, doc);
  });
  return batch.commit();
};

export const fetchInvitedButNotRegisteredUsersFromDB = async () => {
  const db = firebase.firestore();
  return db.collection(INVITED_USER_COLLECTION).where('registered', '==', false).get();
};
/**
 *
 * @param {*} email
 * @returns getAllowedUserDetailsByEmail
 */
export const getAllowedUserDetailsByEmail = async (email) => {
  const db = firebase.firestore();
  return db.collection(INVITED_USER_COLLECTION).where('email', '==', email).get();
};

export const markAllowedUserAsRegisterd = async (email) => {
  const db = firebase.firestore();
  getAllowedUserDetailsByEmail(email).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const user = { ...doc.data(), id: doc.id, registered: true };
      db.collection(INVITED_USER_COLLECTION).doc(user.id).update(user);
    })
  })
};

export const getAllInvitedUsers = async (registeredEmails) => {
  const db = firebase.firestore();
  return await db.collection(INVITED_USER_COLLECTION).where('email', 'not-in', registeredEmails).get();
};

export const getAllAllowedUsers = async () => {
  const db = firebase.firestore();
  return await db.collection(INVITED_USER_COLLECTION).get();
};

export const getAllDonors = async () => {
  const db = firebase.firestore();
  return await db.collection(DONOR_COLLECTION).get();
};

export const getLocationMetadata = async () => {
  const db = firebase.firestore();
  return await db.collection('metadata').doc('md_locations').get();
};
export const getWasteTypeMetadata = async () => {
  const db = firebase.firestore();
  return await db.collection('metadata').doc('md_wasteTypes').get();
};

export const exportWasteMetadata = async () => {
  const db = firebase.firestore();
  return await db.collection('metadata').doc('md_wasteTypes').set(md_wasteTypes);
};

export const exportLocationMetadata = async () => {
  const db = firebase.firestore();
  return await db.collection('metadata').doc('md_locations').set(md_locations);
};

export const addDonation = async (donation) => {
  const db = firebase.firestore();
  return await db.collection('donations').add(donation);
};

export const toggleUserAuthentication = async (uid, status) => {
  const db = firebase.firestore();
  return await db.collection(USER_COLLECTION).doc(uid).update({ isActive: status });
};