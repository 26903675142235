export const md_wasteTypes = {
  md_wasteType: [
    {
      wasteCategory: 'E-waste',
      unitKgValuation: '5',
      wasteTypes: [
        'MIX E-WASTE',
        'COMPUTER-IT MATERIAL',
        'LAPTOP',
        'MONITER-CRT',
        'MONITER-LED',
        'PRINTER/SCANNER/FAX MACHINE/PROJECTOR',
        'CPU',
        'COPIERS',
        'UPS/INVERTOR',
        'HOME APPLIANCES',
        'BOX TV',
        'LCD/LED TV',
        'FRIDGE',
        'WASHING MACHINE',
        'MICROWAVES',
        'AC',
        'HOME THEATER',
        'MIXER/FOOD PROCESSOR',
        'MOBILE/TAB'
      ]
    },
    {
      wasteCategory: 'Plastic',
      unitKgValuation: '5',
      wasteTypes: ['Plastic']
    },
    {
      wasteCategory: 'Thermocol',
      unitKgValuation: '5',
      wasteTypes: ['Thermocol']
    },
    {
      wasteCategory: 'Fabric',
      unitKgValuation: '5',
      wasteTypes: ['Fabric']
    }
  ]
};

export const md_locations = {
  md_locations: [
    {
      location: 'Baner',
      address:
        'Aadish Bungalow, SR No. 33/2/67, Samarth Colony, Bevesli Hills, Samarth nagar, Pancard club, Highway road, Baner',
      coordinators: [
        {
          name: 'Mr. Bokil',
          contact: '8975858029'
        },
        {
          name: 'Mrs. Shubhangi Niphadkar',
          contact: '8552003418'
        }
      ]
    },
    {
      location: 'Kothrud',
      address: 'Harshad Food Center, Guruganesh Nagar, Eklavya College Road',
      coordinators: [{ name: 'Mr. Nitin Rane', contact: '9922943228' }]
    },
    {
      location: 'Karve Road',
      address: 'Sustainable Lifestyle Store, Bungalow No. 34, Kulshree Colony, above Madhav Bag clinic, Karve nagar',
      coordinators: [{ name: 'Mr. Bhushan Patil', contact: '9850000313' }]
    },
    {
      location: 'Paud Road',
      address: 'Tuhin Beuty Parlor Building no. 11, Shop no 4, AnandNagar, Paud Road',
      coordinators: [{ name: 'Mr. Suhas Mahajani', contact: '9420862456' }]
    },
    {
      location: 'Shivtirth Nagar',
      address: 'B-2, Rajgad Co. Hsg. Soc., State bank colony, Paud road',
      coordinators: [{ name: 'Mr. Sudhir Talwalkar', contact: '9881097725' }]
    },
    {
      location: 'Sinhagad Road',
      address: 'Saraswat Bank, Suncity Road',
      coordinators: [{ name: 'Mr. Kaushal', contact: '8857095882' }]
    },
    {
      location: 'Jaydeo Nagar',
      address: 'Gate No. 1 , Jaydeo Nagar, Rajaram Bridge',
      coordinators: [{ name: 'Mr. Suhas Joshi', contact: '9403360365' }]
    },
    {
      location: 'Kalyani Nagar',
      address: 'Joggers Park Kalyani Nagar,',
      coordinators: [{ name: 'Mrs. Vaishali Nadkarni', contact: '9764005689' }]
    },
    {
      location: 'Vadgaon Sheri-1',
      address: 'Opposite to Bramha Suncity, Joggers park',
      coordinators: [
        { name: 'Mr. Dilip Nene', contact: '9420435686' },
        { name: 'Mr. Mohan Prasad', contact: '9801701817' }
      ]
    },
    {
      location: 'Hadapsar',
      address: 'Shree Ram Chouck , Handewadi Road, Hadpsatr',
      coordinators: [{ name: 'Mr. Kiran Gandhi', contact: '9890455594' }]
    },
    {
      location: 'Wanawadi',
      address: 'Apang Kalyankarisanstha, Wanwadi',
      coordinators: [
        { name: 'Mr. Santosh Kini', contact: '9373701448' },
        { name: 'Mrs. Anushka Kajabaje', contact: '9881108661' }
      ]
    },
    {
      location: 'Bavadhan',
      address: 'Savanna Hills ,Ranvara Road, Opp. suryadatta College, Patil Nagar, Bavdhan Pune – 411021.',
      coordinators: [{ name: 'Mr. Arvind Ravale', contact: '8275790560' }]
    },
    {
      location: 'Pashan Sus Road',
      address: 'Janata Shakari Bank Ltd. Sus Road.',
      coordinators: [{ name: 'Mr. Ravindra Bhosale', contact: '9850215933' }]
    },
    {
      location: 'Vadogan Sheri-2',
      address: 'Pandit Deendayal Upadyay PMC Ground',
      coordinators: [{ name: 'Mrs. Lata Hiremath', contact: '9881120441' }]
    },
    {
      location: 'Bibavewadi',
      address: 'Gangadham phase-2, Gangadham, Bibvewadi, pune',
      coordinators: [
        { name: 'Mr. Amar Purohit', contact: '9960269777' },
        { name: 'Mr. Mahesh Joshi', contact: '9890611518' },
        { name: 'Mr. Nitin Atre', contact: '8975751987' },
        { name: 'Mr. Viraj Sule', contact: '9527402643' }
      ]
    },
    {
      location: 'Karvenagar',
      address: 'Amrapali Society',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Kumar Park',
      address: 'Kumar Society, Vitthal Mandir ',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Deccan',
      address: 'Atre Shop',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Collection Center',
      address: 'Collection Center',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Society Drive',
      address: 'Society Drive',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Commercial Drive',
      address: 'Commercial Drive',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'School Drive',
      address: 'School Drive',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Special Drive',
      address: 'Special Drive',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Pocket Drive',
      address: 'Pocket Drive',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Mega Drive',
      address: 'Mega Drive',
      coordinators: [{ name: '', contact: '' }]
    },
    {
      location: 'Mini Mega Drive',
      address: 'Mini Mega Drive',
      coordinators: [{ name: '', contact: '' }]
    }
  ]
};
// export const md_locations = {
//   md_locations: [
//     {
//       location: 'Mega Drive - 27 Feb 2022',
//       address:
//         "Ratnaprabha bungalow, Near Maha E Seva kendra,Panchali sweet lane, Late Digambar Haribhaub Jadhav Road,Vadgaon Budruk, gavthana, Sinhgad Road,Pune 411041",
//       coordinators: [
//         {
//           name: 'Mr. Mandar Samangadkar',
//           contact: '7720054172'
//         }
//       ]
//     }
//   ]
// };
