// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  search: getIcon('ic_search')
};

export const sidebarConfigAdmin = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.adminDashboard, icon: ICONS.dashboard }]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Volunteer',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Invite', path: PATH_DASHBOARD.volunteer.new },
          { title: 'Registered Volunteers', path: PATH_DASHBOARD.volunteer.registeredList },
          { title: 'Invited Volunteers', path: PATH_DASHBOARD.volunteer.invitedList }
        ]
      }
    ]
  },
  // COUPON
  // ----------------------------------------------------------------------
  {
    subheader: 'Coupon',
    items: [
      {
        title: 'Redeem',
        path: PATH_DASHBOARD.general.redeem,
        icon: ICONS.booking
      }
    ]
  },

  // Report
  // ----------------------------------------------------------------------
  {
    subheader: 'Report',
    items: [
      {
        title: 'donation',
        path: PATH_DASHBOARD.general.report,
        icon: ICONS.analytics
      }
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban
  //     }
  //   ]
  // }
];
export const sidebarConfigVolunteer = [
  // DONTATION
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Donor',
  //   items: [
  //     {
  //       title: 'Register',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user
  //     },
  //     {
  //       title: 'Search',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.search
  //     }
  //   ]
  // },
  {
    subheader: 'Dontion',
    items: [
      {
        title: 'Waste Collection',
        path: PATH_DASHBOARD.general.donor,
        icon: ICONS.cart
      }
    ]
  }
];
//export default { sidebarConfigAdmin, sidebarConfigVolunteer };
